import React from "react"
import Seo from "../components/molecules/Seo";
import Header from "../components/molecules/Header";
import Container from "../components/atoms/Container";
import styled from "styled-components";
import SubscribeForm from "../components/atoms/SubscribeForm";
import ColorScheme from "../components/atoms/ColorScheme";
import {graphql} from "gatsby";
import PrismicRichText from "../components/atoms/PrismicRichText";

const ContentHolder = styled.div`
  width: 100%;
  max-width: 30rem;
  margin: 4rem auto;
  @media( ${props => props.theme.breakpoints.md} ) {
    margin: 10rem auto;
  }
  form {
    input[type="email"] {
      margin: 0;
      width: 100%;
      background-color: ${props => props.theme.colours.lightGrey};
    }
    button {
      color: ${props => props.theme.colours.black};
      background-color: ${props => props.theme.secondary};
    }
  }
`;

function SubscribePage({data}) {
  return (
    <ColorScheme primary='magenta' secondary='yellowGreen'>
      <Seo title="Subscribe" />
      <Header title="Subscribe" topColor="yellow" bottomColor="purple" />
      <Container>
        <ContentHolder>
          <PrismicRichText render={data.prismicGlobalSettings.data.subscribe_text.raw}/>
          <SubscribeForm/>
        </ContentHolder>
      </Container>
    </ColorScheme>
  )
}

export default SubscribePage;

export const pageQuery = graphql`
  query SubscribeQuery {
    prismicGlobalSettings {
      data {
        subscribe_text {
          raw
        }
      }
    }
  }
`
